import { project_data } from "./index.js";

const URL_ROOT = "https://plan-studios.com/projects/";
//const URL_ROOT = "https://mapenvy.com/Sandbox/Websites/PLAN/projects/";

/* 
###################################################################################################
* Project Gallery Filter Functions

DOES Control display of project elements in gallery
###################################################################################################
*/
const FILTER_LINKS = document.querySelectorAll('#project-filters span');

FILTER_LINKS.forEach(link => {
    link.addEventListener('pointerup', () => {
        let category = link.dataset.category;
        FILTER_PROJECTS(category);
    })
})

export const FILTER_PROJECTS = (category) => {
    //Turn off active styles
    FILTER_LINKS.forEach(l => { 
        if (l.classList.contains('filter-active')) {
            l.classList.remove('filter-active'); 
        }
    })

    document.querySelectorAll('#project-gallery .grid-item').forEach(box => {
        if (category == 'all') { 
            box.classList.remove('off');

            setTimeout(() => {
                box.classList.remove('delete');
                
            }, 10)
            return;
        } 
        if (box.classList.contains(category)) { //These are items to be shown
            box.classList.remove('off');
            setTimeout(() => {
                box.classList.remove('delete');
                return;
            }, 10)
        } else {
            box.classList.add('delete');

            setTimeout(() => {
                box.classList.add('off');
            }, 500)
        }
    })

    //- Add active styling to the active category
    FILTER_LINKS.forEach(l => { 
        if (l.dataset.category == category) {
            l.classList.add('filter-active'); 
        }
    })
}


/* 
###################################################################################################
* Build Portfolio

DOES Create the project gallery portfolio from the projects.json file
###################################################################################################
*/
const PROJECT_PAGE_HTML =[];

//- Creates the portfolio gallery thumbnail items in main site
const CREATE_PORTFOLIO_ITEM = (name, location, thumb_url, classes) => {
    const TITLE_SPAN = document.createElement('span');
    const LOCATION_SPAN = document.createElement('span');
    TITLE_SPAN.classList.add('title');
    LOCATION_SPAN.classList.add('subtitle');
    TITLE_SPAN.innerText = name;
    LOCATION_SPAN.innerText = location;

    const CONTENT_WRAPPER = document.createElement('div');
    CONTENT_WRAPPER.classList.add('grid-item-content-wrapper');
    CONTENT_WRAPPER.append(TITLE_SPAN);
    CONTENT_WRAPPER.append(LOCATION_SPAN);

    const COVER_DIV = document.createElement('div');
    COVER_DIV.classList.add('grid-item-cover');

    const BG_DIV = document.createElement('div');
    BG_DIV.classList.add('grid-item-bg');
    BG_DIV.style.backgroundImage = 'url('+ thumb_url +')';

    const GRID_ITEM = document.createElement('div');
    GRID_ITEM.classList.add('grid-item');

    for (let i=0; i<classes.length; i++) {
        GRID_ITEM.classList.add(classes[i]);
    }
    GRID_ITEM.setAttribute('data-name', name);

    GRID_ITEM.append(BG_DIV);
    GRID_ITEM.append(COVER_DIV);
    GRID_ITEM.append(CONTENT_WRAPPER);

    GRID_ITEM.addEventListener('pointerup', CALL_PROJECT);

    return GRID_ITEM;
}

//- Called at site load to create project portfolio gallery
export const CREATE_PORTFOLIO = async (project_data) => {
    const PROJECTS = project_data;

    for (let p in PROJECTS) {
        let proj = PROJECTS[p];

        let img_dir = proj.img_dir;
        let name = proj.name;
        let location = proj.location;
        //let thumb = img_dir + '_thumb.jpg';
        //let thumb = '01_thumb.jpg';
        let thumb = '01_thumb.webp';
        //let thumb = '01.jpg';

        //let thumb_url = "../projects/" + img_dir + "/" + thumb; //For Local Testing
        let thumb_url = "./projects/" + img_dir + "/" + thumb; //For Live
      

        let classes = [];
        if (proj.resort === 1) classes.push('resort');
        if (proj.mountain === 1) classes.push('mountain');
        if (proj.estate === 1) classes.push('estate');
        if (proj.community === 1) classes.push('community');

        let class_add = '';
        for (let i=0; i<classes.length; i++) {
            class_add += ' '+classes[i];
        }

        let item = CREATE_PORTFOLIO_ITEM(name, location, thumb_url, classes);
        document.querySelector('#project-gallery').append(item);
    }
}

//- Calls the project to load when thumbnail is clicked
const CALL_PROJECT = async (e) => {


    const GRID_ITEM = e.target.closest('.grid-item');
    const TITLE = GRID_ITEM.dataset.name;

    //document.querySelector('#project-page-contents').scroll(0,0);
    document.querySelector('#project-page-contents').scrollTop = 0;
    const PROJECT = await BUILD_PROJECT(TITLE);



    const PROJECT_CONTAINER = document.querySelector('#project-page-container');
    

    document.querySelector('#project-page-contents').innerHTML = PROJECT;

    document.querySelector('#project-page-contents').scroll(0,0);

    PROJECT_CONTAINER.classList.remove('staged');

    setTimeout(() => {
        document.querySelector('#project-page-container .project-hero').classList.remove('opacity0');
    }, 650)
    setTimeout(() => {
        document.querySelector('#project-page-container .project-description-wrapper').classList.remove('opacity0');
    }, 850)

    setTimeout(() => {
        document.querySelector('#project-previous').classList.remove('opacity0');
    }, 750)
    setTimeout(() => {
        document.querySelector('#close-project-btn').classList.remove('opacity0');
    }, 950)
    setTimeout(() => {
        document.querySelector('#project-next').classList.remove('opacity0');
    }, 850)



    //- Prepare the Next project
    PREPARE_NEXT_PROJECT(TITLE);
   
}

//- Prepares Next/Previous Project for load
const PREPARE_NEXT_PROJECT = (title) => {
    //- Prepare the Next project
    const INDEX = project_data.findIndex(p => p.name == title);
    const LEN = project_data.length;

    let next_index;
    let previous_index;

    if (INDEX == (LEN - 1)) {
        next_index = 0;
    } else {
        next_index = INDEX + 1;
    }

    if (INDEX == 0) {
        previous_index = (LEN - 1);
    } else {
        previous_index = INDEX - 1;
    }

    let NEXT_PROJECT = project_data[next_index].name;
    let PREVIOUS_PROJECT = project_data[previous_index].name;

    document.querySelector('#project-next').setAttribute('project', NEXT_PROJECT);
    document.querySelector('#project-previous').setAttribute('project', PREVIOUS_PROJECT);

    BUILD_PROJECT(NEXT_PROJECT);
    BUILD_PROJECT(PREVIOUS_PROJECT);
}

//- Constructs the HTML to load to the project container page
const BUILD_PROJECT = async (title) => {
    let proj_HTML = '';

    //- Check if the HTMl already exist. 
    let p = PROJECT_PAGE_HTML.find(p => p.name === title);

    if (p) {
        proj_HTML = p.html;
    } else {
        let projects = project_data;
        let project = projects.find(p => p.name === title);

        let img_dir = project.img_dir;

        let name = project.name;
        let location = project.location;
        let scale = project.scale;
        let scope = project.scope;
        let team = project.other_team;
        let other_team = '<h10>PLAN</h10>';
        if (team != '') other_team = '<h10>PLAN</h10> + ' + team;

        if (project.other_firm != '') {
            other_team = project.other_firm;
        }


        let description = project.description;
 
        let hero_img = "./projects/" + img_dir + "/01.webp"; //For Live
        let secondary_img = "./projects/" + img_dir + "/02.webp"; //For Live

        proj_HTML += '<div class="project-wrapper" id="'+ img_dir +'">';
            proj_HTML += '<div class="project-hero opacity0" style="background-image: url(\''+ hero_img +'\');"></div>';
            proj_HTML += '<div class="project-description-wrapper opacity0">';
            proj_HTML += '<div class="project-description-image" style="background-image: url(\''+ secondary_img +'\');"></div>';
                proj_HTML += '<div class="project-description">';
                    proj_HTML += '<div class="project-title title">'+ name +'</div>';
                    proj_HTML += '<div class="project-details">'
                        proj_HTML += '<div class="detail-title content-normal">Location</div>';
                        proj_HTML += '<div class="detail-value content-normal">'+ location +'</div>';
                        proj_HTML += '<div class="detail-title content-normal">Scale</div>';
                        proj_HTML += '<div class="detail-value content-normal">'+ scale +'</div>';
                        proj_HTML += '<div class="detail-title content-normal">Scope</div>';
                        proj_HTML += '<div class="detail-value content-normal">'+ scope +'</div>';
                        proj_HTML += '<div class="detail-title content-normal">Team</div>';
                        proj_HTML += '<div class="detail-value content-normal">'+ other_team +'</div>';
                    proj_HTML += '</div>';
                    proj_HTML += '<div class="description content-normal">';
                    proj_HTML += description;
                    proj_HTML += '</div>';
                proj_HTML += '</div>';
            proj_HTML += '</div>';

            proj_HTML += '<div class="project-images-wrapper">';

        //- Get the project gallery images
        //let fetch_url = URL_ROOT + img_dir +"/gallery"; //For Live

        let fetch_url = './projects/' + img_dir +"/gallery"; //For Live
        
        let elements = await fetch(fetch_url)
            .then((res) => {
            return res.text()})
            .then((data) => {
                let parser = new DOMParser;
                let text_2_html = parser.parseFromString(data, 'text/html');
                let items = text_2_html.getElementsByTagName('a');

                return items;
            })

        for (let x of elements) {
            //if ( x.href.match(/\.(jpe?g|png|gif|webp)$/) ) { 
            if ( x.href.match(/\.(jpe?g|png|gif|webp)$/) ) { 
                
                //let imgs = await GET_PROJECT_IMAGES(x.href, img_dir); // For Local Texting
                let imgs = await GET_PROJECT_IMAGES(x.innerText, img_dir); //For Live
                proj_HTML += imgs;
            }
        };
   
            proj_HTML += '</div>'; // End of project images wrapper.  Add after all images loaded.
        proj_HTML += '</div>'; // End of project wrapper.  Add after all images loaded.

        //- Store the project page HTML with project name for quick retrieval later.
        PROJECT_PAGE_HTML.push({name: name, html: proj_HTML});
    }
    return proj_HTML;
}

//- Get the project images from the project dirctory
const GET_PROJECT_IMAGES = async (x, img_dir) => {
    return new Promise((res) => {
        //let img_path = x; //For local testing
        //let img_path = URL_ROOT + img_dir +"/gallery/"+ x //For Live

        let img_path = './projects/' + img_dir +"/gallery/"+ x //For Live

        const img = new Image();
        let proj_HTML = '';
        img.onload = () => {
            let h = img.height;
            let w = img.width;
            let img_class = 'half'; //- Set image to 'half' class as default.  Sets 2 square images side by side in gallery.

            //- Determine if image is not square.
            //- If portrait|landscape then set the class accordingly.
            if (h > w) { //- Portrait
                img_class = 'tall';
            } else if (w > h) { //- Landscape
                img_class = 'full';
            };
            //let img_src = img.src; //;For Local Testing only
            let img_src = img_path;

            proj_HTML += '<div class="'+ img_class +'"><img src="'+ img_src +'" class="" /></div>';

            res(proj_HTML);
        }
       // img.src = x; //For testing locally
       img.src = img_path;
    })
}

//- Loads Next/Previous project when corresponding button is clicked in the project navigation bar
const ADVANCE_PROJECT = async (title, direction) => {
    //- Fade out the current project
    document.querySelector('#project-page-container .project-hero').classList.add('opacity0');
    document.querySelector('#project-page-container .project-description-wrapper').classList.add('opacity0');
    document.querySelector('#project-page-contents').classList.add('opacity0');

    let project = await BUILD_PROJECT(title);

    let project_container = document.querySelector('#project-page-container');

    document.querySelector('#project-page-contents').innerHTML = project;

    document.querySelector('#project-page-contents').scrollTop = 0;
    document.querySelector('#project-page-contents').scroll(0,0);


    document.querySelector('#project-page-contents').classList.remove('opacity0');
    setTimeout(() => {
        document.querySelector('#project-page-container .project-hero').classList.remove('opacity0');
    }, 650)
    setTimeout(() => {
        document.querySelector('#project-page-container .project-description-wrapper').classList.remove('opacity0');
    }, 850)

    PREPARE_NEXT_PROJECT(title);
}


/* 
###################################################################################################
* Close Project Page Functions

DOES Creates functions for clsoing th eproject page overlay.
###################################################################################################
*/

document.querySelector('#close-project-page').addEventListener('click', () => {
    document.querySelector('#project-page-contents').classList.add('opacity0');
    document.querySelector('.project-page-navigation').classList.add('opacity0');

    setTimeout(() => {
        document.querySelector('#project-page-container').classList.add('shrink');
    }, 350)

    setTimeout(() => {
        document.querySelector('#project-page-container').classList.add('off');
    }, 1000)

    setTimeout(() => {
        document.querySelector('#project-page-container').classList.add('staged');
        document.querySelector('#project-page-container').classList.remove('shrink');
        document.querySelector('#project-page-container').classList.remove('off');

        document.querySelector('#project-page-contents').classList.remove('opacity0');
    document.querySelector('.project-page-navigation').classList.remove('opacity0');
    }, 1100)
})


/* 
###################################################################################################
* Project Page Navigation Functions

DOES Creates functions for advancing to Previous or Next project.
###################################################################################################
*/

document.querySelector('#project-next').addEventListener('pointerup', async (e) => {
    let PROJECT_TITLE = document.querySelector('#project-next').getAttribute('project');
    ADVANCE_PROJECT(PROJECT_TITLE);
})

document.querySelector('#project-previous').addEventListener('pointerup', async (e) => {
    let PROJECT_TITLE = document.querySelector('#project-previous').getAttribute('project');
    ADVANCE_PROJECT(PROJECT_TITLE);
})


