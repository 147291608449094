import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { TextPlugin } from "gsap/TextPlugin";

import SplitType from 'split-type';

const SLIDER = document.querySelector('#slider');
const SLIDES = SLIDER.querySelectorAll('.slide');

const SLIDE_COUNT = SLIDES.length;
let cycle_count = Number(0);

let count = Number(0);

const TRANSITION_DURATION = 1000;

const DEACTIVATE_TIMING = {
    duration: 0,
    iteration: 1,
    fill: 'forwards'
};
const DEACTIVATE_ACTIVE = [
    {opacity: 0}
]

const TIMING = {
    duration: TRANSITION_DURATION,
    iteration: 1,
    fill: 'forwards'
};
const TIMING_SHORT = {
    duration: 50,
    iteration: 1,
    fill: 'forwards'
};
const REVEAL_STAGED = [
    {opacity: 1}
]

let slide_interval;
let transition_timeout;

const PLAY_SLIDER = () => {
    slide_interval = setInterval(() => {

        count++;
        if (count === SLIDE_COUNT) { 
            count = 0; 
            cycle_count++;

            if ((cycle_count === 1) && (document.querySelector('#main').scrollTop < 90)) {
                SHOW_START_SCROLL();
            }
        }

        let active = SLIDER.querySelector('[active]');
        let staged = SLIDER.querySelector('[staged]');

        let media_type = staged.firstChild.tagName;
        if (media_type !== 'IMG') {
            staged.firstChild.nextSibling.currentTime = 0;
        }

        staged.animate(REVEAL_STAGED, TIMING);

        transition_timeout = setTimeout(() => { 
            active.animate(DEACTIVATE_ACTIVE, DEACTIVATE_TIMING);
            active.setAttribute('inactive', '');
            active.removeAttribute('active');

            staged.setAttribute('active','');
            staged.removeAttribute('staged');

            let next_stage = count + 1;
        
            if (next_stage === SLIDE_COUNT) { next_stage = 0; }

            SLIDES[next_stage].setAttribute('staged', '');
            SLIDES[next_stage].removeAttribute('inactive');
        }, 1400)
    }, 5000)
}

export const RESET_SLIDER = () => {
    clearTimeout(transition_timeout);
    clearInterval(slide_interval);
    count = 0;

    document.querySelector('#hero-video').currentTime = 0;

    SLIDES.forEach(slide => {
        slide.removeAttribute('active');
        slide.removeAttribute('staged');
        slide.setAttribute('inactive','');
    })

    document.querySelector('.slide:nth-child(1)').removeAttribute('inactive');
    document.querySelector('.slide:nth-child(2)').removeAttribute('inactive');

    document.querySelector('.slide:nth-child(1)').setAttribute('active','');
    document.querySelector('.slide:nth-child(2)').setAttribute('staged','');

    document.querySelector('.slide:nth-child(1)').animate(REVEAL_STAGED, TIMING_SHORT);
    document.querySelector('.slide:nth-child(2)').animate(DEACTIVATE_ACTIVE, TIMING_SHORT);

    const SLOGAN_SPANS = document.querySelectorAll('.slogan span');
    SLOGAN_SPANS.forEach(span => {
        span.classList.add('opacity0');
    })

    document.querySelector('.slogan').style.opacity = 1;
    document.querySelector('.slogan').classList.remove('off');

    ENTER_SITE();
}

export const ENTER_SITE = () => {
    const SLOGAN = document.querySelector('.slogan');
    const WORDS = document.querySelectorAll('.slogan span');

    const CHARS_1 = new SplitType(WORDS[0], {types: 'chars'});
    const CHARS_2 = new SplitType(WORDS[1], {types: 'chars'});
    const CHARS_3 = new SplitType(WORDS[2], {types: 'chars'});
    const CHARS_4 = new SplitType(WORDS[3], {types: 'chars'});

    setTimeout(() => {
        WORDS[0].classList.remove('opacity0');
        gsap.from(CHARS_1.chars, {opacity: 0, duration: 0.3, stagger: 0.05})
    }, 2000)

    setTimeout(() => {
        WORDS[1].classList.remove('opacity0');
        gsap.from(CHARS_2.chars, {opacity: 0, duration: 0.3, stagger: 0.05})
    }, 2630)

    setTimeout(() => {
        WORDS[2].classList.remove('opacity0');
        gsap.from(CHARS_3.chars, {opacity: 0, duration: 0.3, stagger: 0.05})
    }, 3400)

    setTimeout(() => {
        WORDS[3].classList.remove('opacity0');
        gsap.from(CHARS_4.chars, {opacity: 0, duration: 0.3, stagger: 0.05})
    }, 4170)

    setTimeout(() => {
        gsap.to(SLOGAN, {opacity: 0, duration: 0.8, stagger: 0.15})
    }, 6000)

    setTimeout(() => {
        SLOGAN.classList.add('off');
    }, 7200)
 
    setTimeout(() => { SLIDER.style.display = 'block'; }, 5000)

    setTimeout(() => {
        SLIDER.style.opacity = 1;
        PLAY_SLIDER();    
    }, 7600)
}


const SHOW_START_SCROLL = () => {
    const SCROLL_START = document.querySelector('.start-wrapper');

    SCROLL_START.classList.remove('off');
    setTimeout(() => { SCROLL_START.classList.remove('opacity0'); }, 50)
}