import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { TextPlugin } from "gsap/TextPlugin";

//import SplitType from 'split-type';

const SCROLLER = document.querySelector('#main');


const EXPAND_CHARS = (el) => {
    gsap.from(el.chars, {
        scrollTrigger: {
            scroller: SCROLLER,
            trigger: el.chars[0],
            start: "top 75%",
            scrub: false,
        },
        duration: 0.7,
        opacity: 0,
        letterSpacing: '-0.5em',
        ease: 'power2.out'
    })
}

const REVEAL_LINES = (el) => {
    gsap.from(el.lines, {
        scrollTrigger: {
            scroller: SCROLLER,
            trigger: el.lines,
            start: "top 75%",
            scrub: false,
        },
        duration: 1.2,
        stagger: 0.4,
        opacity: 0,
        y: 100,
        ease: 'expo.out'
    })
}

const REVEAL_LINES_FAST = (el) => {
    gsap.from(el.lines, {
        scrollTrigger: {
            scroller: SCROLLER,
            trigger: el.lines,
            start: "top 75%",
            scrub: false
        },
        duration: 1.2,
        stagger: 0.2,
        opacity: 0,
        y: 100,
        ease: 'expo.out'
    })
}

const HEADERS = document.querySelectorAll('.header');
const SUBHEADERS = document.querySelectorAll('.subheader');

const PRACTICE_LINES = new SplitType(document.querySelectorAll('#practice .wrapper'));

const LEADERSHIP_LINES = new SplitType(document.querySelectorAll('#leadership .body-regular'));
const LEADERSHIP_SLOGAN = new SplitType(document.querySelector('#leadership .slogan'));

const SERVICE_TITLES = document.querySelectorAll('#services .title');
const SERVICE_LINES = new SplitType(document.querySelectorAll('#services .body-regular'));

const CONTACT_SLOGAN_SML = new SplitType(document.querySelectorAll('#contact .slogan-sml'));
const CONTACT_SLOGAN_LRG = new SplitType(document.querySelectorAll('#contact .wrapper h1'));
//const CONTACT_BTM = new SplitType(document.querySelectorAll('#contact .content.btm'));

export const SET_ANIMATIONS = () => {
    HEADERS.forEach(header => {
        const LETTERS = new SplitType(header);
        EXPAND_CHARS(LETTERS);
    })

    SUBHEADERS.forEach(header => {
        const LETTERS = new SplitType(header);
        EXPAND_CHARS(LETTERS);
    })

    REVEAL_LINES(PRACTICE_LINES);

    REVEAL_LINES(LEADERSHIP_LINES);
    REVEAL_LINES(LEADERSHIP_SLOGAN);

    SERVICE_TITLES.forEach(title => {
        const LETTERS = new SplitType(title);
        EXPAND_CHARS(LETTERS);
    })

    REVEAL_LINES_FAST(SERVICE_LINES);


    REVEAL_LINES(CONTACT_SLOGAN_SML);
    REVEAL_LINES(CONTACT_SLOGAN_LRG);

    //- Images
    const IMG_TOP = document.querySelector('#leadership .wrapper.top .wrapper-right');
    const IMG_BTM = document.querySelector('#leadership .wrapper.btm .wrapper-left');

    gsap.from(IMG_TOP, {
        scrollTrigger: {
            scroller: SCROLLER,
            trigger: IMG_TOP,
            start: "top 75%",
            scrub: false
        },
        duration: 1.2,
        opacity: 0,
        x: 200,
        ease: 'expo.out'
    })

    gsap.from(IMG_BTM, {
        scrollTrigger: {
            scroller: SCROLLER,
            trigger: IMG_BTM,
            start: "top 75%",
            scrub: false
        },
        duration: 1.2,
        opacity: 0,
        x: -200,
        ease: 'expo.out'
    })

}