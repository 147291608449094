import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { TextPlugin } from "gsap/TextPlugin";

import Lenis from 'lenis';


import { SET_ANIMATIONS } from './_animate.js';
import { FILTER_PROJECTS, CREATE_PORTFOLIO } from './_projects.js';
import { ENTER_SITE, RESET_SLIDER } from './_slider.js';

export let project_data;

window.onload = () => {
    // Read the project list and create the portfolio grid
    ( async () => {
        let response = await fetch('./projects/projects.json');
        let data = await response.json();

        project_data = data.projects;

        CREATE_PORTFOLIO(project_data);

        ACTIVATE_LENIS();

        //await CREATE_PORTFOLIO(project_data);

        //- Need to call SET_ANIMATIONS() from inside async function to ensure that GSAP ScrollTriggers are set when animated content is in final location.
        SET_ANIMATIONS();
        SET_PARALLAX();
    })()

   // ACTIVATE_LENIS();
    ENTER_SITE();
};











//-========================================================================================
//- Hamburger Menu
//-========================================================================================
const HAMBURGER = document.querySelector('#hamburger');

HAMBURGER.addEventListener('pointerup', () => {
    if (!HAMBURGER.hasAttribute('active', '')) { 
        OPEN_NAVIGATION();
    } else {
        CLOSE_NAVIGATION();
    }
})

const OPEN_NAVIGATION = () => {
    if (HAMBURGER.hasAttribute('active', '')) { return ; }
    document.querySelector('.nav-mobile-wrapper').style.top = 0;
    HAMBURGER.setAttribute('active', '');

    const NAV = document.querySelector('header');

    if (!NAV.hasAttribute('active')) {
        NAV.setAttribute('active','');

        document.querySelector('#line-top').style.stroke = "black";
        document.querySelector('#line-middle').style.stroke = "black";
        document.querySelector('#line-bottom').style.stroke = "black";
    }
}

const CLOSE_NAVIGATION = () => {
    document.querySelector('.nav-mobile-wrapper').style.top = '-100%';
    HAMBURGER.removeAttribute('active');
}

const NAV_MOBILE_BTNS = document.querySelectorAll('.nav-mobile-wrapper a');
NAV_MOBILE_BTNS.forEach(btn => {
    btn.addEventListener('pointerup', () => {
        CLOSE_NAVIGATION();
    })
})

//-========================================================================================
//- Logo Funcitons
//-========================================================================================

const LOGO = document.querySelector('#logo');
LOGO.addEventListener('pointerup', () => {
    lenis.scrollTo('#hero');
    document.querySelector('#slider').style.opacity = 0;

    RESET_SLIDER();
})

//-========================================================================================
//- Nav Menu Listener --> Toggles sticky menu
//-========================================================================================
const MAIN = document.querySelector('#main');


const MONITOR_SCROLL = (e) => {
    const POS = MAIN.scrollTop;
    const NAV = document.querySelector('header');
    const GRADIENT = document.querySelector('.bg-gradient');
    const SCROLL_START = document.querySelector('.start-wrapper');

    (POS > 90) ? NAV.setAttribute('active','') : NAV.removeAttribute('active');
    (POS > 90) ? GRADIENT.removeAttribute('active') : GRADIENT.setAttribute('active','');

    (POS > 90) ? document.querySelector('#line-top').style.stroke = "black" : document.querySelector('#line-top').style.stroke = "white";
    (POS > 90) ? document.querySelector('#line-middle').style.stroke = "black" : document.querySelector('#line-middle').style.stroke = "white";
    (POS > 90) ? document.querySelector('#line-bottom').style.stroke = "black" : document.querySelector('#line-bottom').style.stroke = "white";

    if ((POS > 90) && (!SCROLL_START.classList.contains('opacity0'))) {
        SCROLL_START.classList.add('opacity0');
        setTimeout(() => { SCROLL_START.classList.add('off') }, 450)
    }
 
}
//window.addEventListener('wheel', MONITOR_SCROLL);
MAIN.addEventListener('scroll', MONITOR_SCROLL);


const SET_PARALLAX = () => {
    //-========================================================================================
    //- 'Practice' Parallax Handler
    //-========================================================================================

    const PLAX_BG = document.querySelector('#practice .plax-bg');
    gsap.from(PLAX_BG, {
        scrollTrigger: {
            scroller: document.querySelector('#main'),
            trigger: PLAX_BG,
            start: "top bottom",
            end: "bottom top",
            scrub: true,
        },
        y: 0,
        ease: 'sine.inOut'
    })

    //-========================================================================================
    //- 'SERVICES' Parallax Handler
    //-========================================================================================

    const PLAX_BG_SERVICES = document.querySelector('#services .plax-bg');
    gsap.from(PLAX_BG_SERVICES, {
        scrollTrigger: {
            scroller: document.querySelector('#main'),
            trigger: PLAX_BG_SERVICES,
            start: "top bottom",
            end: "bottom top",
            scrub: true,
        },
        y: 0,
        ease: 'sine.inOut'
    })
}

//-========================================================================================
//- Navigation Functions
//-========================================================================================
const GO_TO_PAGE = (e) => {
    const NAV = e.target;
    lenis.scrollTo(NAV.dataset.page);
}

const NAV_BTNS = document.querySelectorAll('nav a');
NAV_BTNS.forEach(nav => [
    nav.addEventListener('pointerup', GO_TO_PAGE)
])

//-========================================================================================
//- Activate Lenis Smooth Scroll
//-========================================================================================
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, TextPlugin);
let lenis;
let activated = false;

export const ACTIVATE_LENIS = (e) => {
    lenis = new Lenis({ wrapper: document.querySelector('#main')});

    lenis.on('scroll', (e) => {
       //e.preventDefault();
    })
    
    function raf(time) {
        lenis.raf(time)
        requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)
}
